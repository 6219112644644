<template>
  <v-dialog
    :value="value"
    :max-width="maxWidth"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card>
      <v-card-title class="text-h5">{{
        isEdit ? "Редактировать компетенцию" : "Создать компетенцию"
      }}</v-card-title>
      <v-card-text class="black--text">
        <validation-observer
          ref="form"
          tag="form"
          @submit.prevent="handleSubmit"
        >
          <validation-provider
            v-slot="{ errors }"
            :rules="{ required: true, min: 3, max: 250 }"
            name="name"
            slim
          >
            <v-text-field
              v-model="name"
              label="Название компетенции"
              :error-messages="errors"
              class="mb-3"
              outlined
            ></v-text-field>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            :rules="{ required: true, min: 3, max: 250 }"
            slim
            name="code"
          >
            <v-text-field
              v-model="code"
              label="Код"
              :disabled="isEdit"
              :error-messages="errors"
              hint="Уникальный код компетенции. Служит для идентификации. Нельзя изменить после создания. Например: python"
              outlined
              persistent-hint
              class="mb-3"
            ></v-text-field>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            :rules="{ required: true }"
            slim
            name="group_id"
          >
            <v-select
              v-model="group_id"
              :items="groupOptions"
              label="Группа"
              item-text="name"
              item-value="id"
              dense
              :error-messages="errors"
              outlined
            ></v-select>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            :rules="{
              min: 3,
              max: 500,
            }"
            slim
            name="description"
          >
            <v-textarea
              v-model="description"
              label="Описание компетенции"
              :error-messages="errors"
              hint="Будет отображаться на странице компетенции в таланте. Можно писать в MarkDown разметке"
              persistent-hint
              outlined
              class="mb-3"
            ></v-textarea>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            :rules="{
              required: true,
              min_value: 0.01,
              max_value: 10,
              double: true,
            }"
            slim
            name="limit_coefficient"
          >
            <v-text-field
              v-model.number="limitСoefficient"
              label="Ограничивающий коэффициент"
              :error-messages="errors"
              hint="Коэффициент, ограничивающий максимальный балл за компетенцию пределом limit_coefficient * балл за главный трек"
              persistent-hint
              class="mb-3"
              outlined
            ></v-text-field>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            :rules="{
              integer: true,
              required: isEdit && !!competence.event_id,
            }"
            slim
            name="event_id"
          >
            <v-text-field
              v-model.number="event_id"
              label="ID мероприятия"
              :error-messages="errors"
              hint="ID мероприятия, куда будут записаны достижения"
              persistent-hint
              class="mb-3"
              type="number"
              outlined
            ></v-text-field>
          </validation-provider>

          <v-checkbox
            v-model="is_active"
            label="Выводить в ЛК"
            persistent-hint
            hint="Если галочка не стоит, то эта компетенция не будет отображаться в ЛК Таланта"
          >
          </v-checkbox>
        </validation-observer>
        <div v-if="error" class="error--text">{{ error }}</div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="handleSubmit">{{
          isEdit ? "Сохранить" : "Создать"
        }}</v-btn>
        <v-btn color="primary" outlined @click="handleClose">Отмена</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DEFAULT_DIALOG_WIDTH } from "@/constants";
import { apiClient } from "@/api";
import { pathJoin } from "@/api/utils";
const initialState = () => {
  return {
    pending: false,
    error: "",
    name: "",
    code: "",
    limitСoefficient: "",
    event_id: "",
    group_id: "",
    description: "",
    is_active: true,
  };
};
export default {
  name: "CompetenceCreateDialog",
  props: {
    value: Boolean,
    maxWidth: {
      type: String,
      default: DEFAULT_DIALOG_WIDTH,
    },
    competence: {
      type: Object,
    },
  },
  data() {
    return {
      ...initialState(),
    };
  },
  computed: {
    isEdit() {
      return !!this.competence?.id;
    },
    groupOptions() {
      return Object.values(this.$store.state.groups).map((n) => {
        return {
          id: n.id,
          name: n.name,
        };
      });
    },
  },
  watch: {
    value: {
      handler(val) {
        // скидывем форму при закрытии
        if (!val) {
          this.reset();
        } else {
          this.mapCompetence();
        }
      },
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, initialState());
      this.$refs.form.reset();
    },
    handleClose() {
      this.$emit("input", false);
    },
    async handleSubmit() {
      const {
        name,
        code,
        limitСoefficient: limit_coefficient,
        event_id,
        description,
        is_active,
        isEdit,
        group_id,
      } = this;
      this.error = "";
      if (this.pending) return;
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      const payload = {
        name,
        code,
        limit_coefficient,
        is_active,
        description,
        ...(event_id && { event_id }),
        ...(group_id && { group_id }),
      };

      const config = {
        method: "POST",
        url: "/competences",
        data: payload,
      };

      if (isEdit) {
        config.method = "PATCH";
        delete config.data.code;
        config.url = pathJoin(config.url, this.competence.id);
      }

      try {
        this.pending = true;
        const { data } = await apiClient(config);
        console.log("data", data);
        if (isEdit) {
          this.$store.commit("PATCH_COMPETENCE", data);
        } else {
          this.$store.commit("ADD_COMPETENCE", data);
          this.$emit("onSubmit", data);
        }
        this.handleClose();
      } catch (error) {
        if (error.field) {
          this.$refs.form.setErrors({
            [error.field]: [error.message],
          });
        } else {
          this.error = error.message;
        }
      }
      this.pending = false;
    },
    mapCompetence() {
      const { competence } = this;
      if (!competence) return;
      this.name = competence.name;
      this.code = competence.code;
      this.limitСoefficient = competence.limit_coefficient;
      this.event_id = competence.event_id;
      this.description = competence.description;
      this.is_active = competence.is_active;
      this.group_id = competence.group_id;
    },
  },
};
</script>

<style></style>
