<template>
  <v-container>
    <div v-if="competence">
      <div class="mb-4">
        <router-link :to="{ name: 'home' }">Все компетенции</router-link>
        <div class="d-flex align-center">
          <v-menu offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                fab
                dark
                small
                class="mr-4"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-tune</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="settingsDialog = true">
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-tools</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Настройки компетенции</v-list-item-title>
              </v-list-item>
              <v-list-item @click="compLevelDialog = true">
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-tune-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Пороговые баллы</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{
                  name: 'competence_scores',
                }"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-chart-bar</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Баллы участников</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{
                  name: 'competence_sync',
                }"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-sync</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Пересчет баллов</v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{
                  name: 'competence_achievements_sync',
                }"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-certificate</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Выдача достижений</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <h1 class="flex-grow-1">
            Компетенция &laquo;{{ competence.name }}&raquo;
          </h1>
        </div>
      </div>
      <v-alert
        v-if="!pending && competenceWarnings.length"
        border="left"
        colored-border
        type="warning"
        class="my-4"
        elevation="2"
      >
        <p>
          <strong>Параметры компетенции!</strong>
        </p>
        <ul>
          <li v-for="(item, idx) in competenceWarnings" :key="idx">
            {{ item }}
          </li>
        </ul>
      </v-alert>

      <router-view
        :competence="competence"
        :current-tracks="currentTracks"
        :thresholds="thresholds"
        @onUpdateTracks="getCompTracks"
        @openTresholds="compLevelDialog = true"
        @onOpenAssignDialog="assignDialog = true"
      ></router-view>
    </div>
    <assign-track-dialog
      v-model="assignDialog"
      :competence="competence"
      :selected-tracks="compTracks"
      @onSubmit="getCompTracks"
    />
    <competence-create-dialog
      v-model="settingsDialog"
      :competence="competence"
    />
    <competence-threshold
      v-model="compLevelDialog"
      :competence="competence"
      :thresholds="thresholds"
      @onUpdate="getThresholds"
      @onRemoveSetting="removeSetting"
    />
  </v-container>
</template>

<script>
import AssignTrackDialog from "@/components/dialogs/AssignTrackDialog";
import { apiClient } from "@/api";
import { TRACK_TYPE_EVENT } from "@/constants";
import CompetenceCreateDialog from "@/components/dialogs/CompetenceCreateDialog";
import CompetenceThreshold from "@/components/dialogs/CompetenceThreshold";

export default {
  name: "Competence",
  components: {
    AssignTrackDialog,
    CompetenceCreateDialog,
    CompetenceThreshold,
  },
  metaInfo: {
    title: "Компетенций конкруса",
  },
  data() {
    return {
      assignDialog: false,
      compTracks: [],
      pending: true,
      settingsDialog: false,
      eventPending: false,
      compLevelDialog: false,
      thresholds: [],
    };
  },
  computed: {
    compId() {
      return Number(this.$route.params.id);
    },
    competence() {
      return this.$store.getters["competenceById"](+this.$route.params.id);
    },
    tracks() {
      return this.$store.state.tracks;
    },
    currentTracks() {
      const main = this.compTracks.find((n) => n.main);
      const mainTrack = main && this.tracks.find((t) => t.id === main.track_id);
      return this.compTracks
        .map((n) => {
          const result = {
            ...n,
            track: this.tracks.find((t) => t.id === n.track_id),
            order: 0,
          };
          if (main) {
            if (n.id === main.id) {
              result.order = 100;
            } else {
              result.order = result.track?.type === mainTrack.type ? 1 : 0;
            }
          } else {
            result.order = result.track?.type === TRACK_TYPE_EVENT ? 1 : 0;
          }
          return result;
        })
        .sort((a, b) => {
          return a.order > b.order ? -1 : 1;
        });
    },
    competenceWarnings() {
      const { competence, thresholds } = this;
      const result = [];
      if (!competence) return result;
      if (!competence.event_id) {
        result.push("Не указано ID мероприятия");
      }

      if (!competence.is_active) {
        result.push("Компетенция скрыта в личном кабинете Таланта");
      }

      if (this.compTracks?.length > 1) {
        const main = this.compTracks.find((n) => n.main);
        if (!main) {
          result.push("Отсутствует главный трек компетенции");
        }
      }

      if (!thresholds.length) {
        result.push("Не указаны пороговые баллы");
      }

      return result;
    },
  },
  watch: {
    "competence.event_id": {
      handler() {
        this.getCompEvent();
      },
      immediate: true,
    },
    compId: {
      handler(id) {
        if (id) {
          this.init();
        }
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.pending = true;
      const requests = [
        this.$store.dispatch("talent/getAllAchievementRoles"),
        this.$store.dispatch("getAllGroups"),
      ];
      if (!this.tracks.length) {
        requests.push(this.$store.dispatch("getAllTracks"));
      }
      if (!this.competence) {
        requests.push(this.$store.dispatch("getAllCompetence"));
      }

      await Promise.all(requests);
      await Promise.all([this.getCompTracks(), this.getThresholds()]);
      this.pending = false;
    },
    async getCompEvent() {
      if (this.competence?.event_id) {
        this.eventPending = true;
        await this.$store.dispatch("talent/getEventsByIds", [
          this.competence.event_id,
        ]);
        this.eventPending = false;
      }
    },
    async getCompTracks() {
      const { data } = await apiClient({
        method: "GET",
        url: `/competences/${this.$route.params.id}/tracks`,
      });
      this.compTracks = data.results;
    },
    async getThresholds() {
      try {
        const { data } = await apiClient({
          method: "GET",
          url: `/competences/${this.competence.id}/thresholds`,
        });
        this.thresholds = data.results || [];
      } catch (error) {
        this.thresholds = [];
        this.$toast(`Не удалось получить пороговые баллы. ${error.message}`, {
          type: "error",
        });
      }
    },

    async removeSetting(id) {
      const idx = this.thresholds.findIndex((item) => item.id === id);
      if (idx !== -1) {
        this.thresholds.splice(idx, 1);
      }
    },
  },
};
</script>

<style></style>
