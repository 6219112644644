<template>
  <v-dialog
    :value="value"
    :max-width="maxWidth"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card>
      <v-card-title class="text-h5">Пороговые баллы компетенции</v-card-title>
      <v-card-text class="black--text">
        <p>
          Укажите минимальные баллы для получения достижения в конкурсе Талант
          по этой компетенции
        </p>
        <validation-observer
          ref="createForm"
          tag="form"
          mode="lazy"
          @submit.prevent="handleAddRole"
        >
          <v-row>
            <v-col md="6">
              <validation-provider
                v-slot="{ errors }"
                :rules="{ required: true }"
                vid="new_role"
              >
                <v-select
                  v-model="selectedRole"
                  :items="rolesOptions"
                  label="Выбрать роль*"
                  item-text="name"
                  item-value="id"
                  :error-messages="errors"
                  dense
                  outlined
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col md="3">
              <validation-provider
                v-slot="{ errors }"
                :rules="{ required: true }"
                vid="new_role_scores"
              >
                <v-text-field
                  v-model.number="selectedScores"
                  outlined
                  type="number"
                  step="0.1"
                  dense
                  label="Баллы"
                  :error-messages="errors"
                  name="new_role_scores"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col md="3">
              <v-btn block type="submit" color="primary">Добавить</v-btn>
            </v-col>
          </v-row>
        </validation-observer>
        <validation-observer
          ref="form"
          tag="form"
          @submit.prevent="handleSubmit"
        >
          <div v-for="role in form" :key="role.id">
            <validation-provider
              v-slot="{ errors }"
              tag="div"
              :rules="{
                required: true,
                double: true,
                min_value: 0,
              }"
              :name="role.name"
            >
              <v-text-field
                v-model.number="role.value"
                outlined
                type="number"
                step="0.1"
                :error-messages="errors"
                :loading="deleteLoading[role.id]"
                :label="`Роль ${role.name}`"
                append-icon="mdi-trash-can-outline"
                @click:append="handleRemove(role)"
              ></v-text-field>
            </validation-provider>
          </div>
        </validation-observer>
        <div v-if="error" class="error--text">{{ error }}</div>
      </v-card-text>
      <v-card-actions class="px-5 pb-4">
        <v-btn :loading="pending" color="primary" @click="handleSubmit"
          >Сохранить</v-btn
        >
        <v-btn color="primary" outlined @click="handleClose">Отмена</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DEFAULT_DIALOG_WIDTH } from "@/constants";
import { apiClient } from "@/api";
import { pathJoin } from "@/api/utils";
// import { apiClient } from "@/api";
const initialState = () => {
  return {
    pending: false,
    error: "",
    form: [],
    selectedRole: null,
    selectedScores: 0,
    deleteLoading: {},
  };
};
export default {
  name: "CompetenceThreshold",
  props: {
    value: Boolean,
    maxWidth: {
      type: String,
      default: DEFAULT_DIALOG_WIDTH,
    },
    competence: {
      type: Object,
    },
    thresholds: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      ...initialState(),
    };
  },
  computed: {
    allRoles() {
      return this.$store.state.talent.achievementRoles;
    },
    rolesOptions() {
      const { form, allRoles } = this;
      const excludeIds = form.map((item) => item.id);
      return allRoles.filter((role) => !excludeIds.includes(role.id));
    },
    lowerBounds() {
      return this.thresholds.reduce((acc, value) => {
        acc[value.role_id] = {
          value: value.lower_bound,
          setting_id: value.id,
        };
        return acc;
      }, {});
    },
  },
  watch: {
    value: {
      handler(val) {
        // скидывем форму при закрытии
        if (!val) {
          this.reset();
        } else {
          this.makeForm();
        }
      },
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, initialState());
      this.$refs.form.reset();
      this.$refs.createForm.reset();
    },
    handleClose() {
      this.$emit("input", false);
    },
    makeForm() {
      const { lowerBounds, allRoles, thresholds } = this;
      const roles = thresholds.reduce((acc, value) => {
        const role = allRoles.find((role) => role.id === value.role_id);
        if (role) {
          return [
            ...acc,
            {
              value: lowerBounds[role.id]?.value || 0,
              name: role.name,
              id: role.id,
              setting_id: lowerBounds[role.id]?.setting_id,
            },
          ];
        }
        return acc;
      }, []);
      this.form = roles;
    },
    async handleRemove(formItem) {
      const { form } = this;
      if (formItem.id in this.deleteLoading) {
        return;
      }

      const idx = form.findIndex((item) => item.id === formItem.id);
      if (idx === -1) {
        return;
      }
      const settingId = formItem.setting_id;
      // если есть id значит нужно удалить из базы
      if (settingId) {
        this.$set(this.deleteLoading, formItem.id, 1);
        try {
          await apiClient({
            method: "DELETE",
            url: `/competences/${this.competence.id}/thresholds/${settingId}`,
          });
          this.$toast(`Пороговые баллы для роли ${formItem.name} удалены`, {
            type: "success",
          });
          this.$emit("onRemoveSetting", settingId);
        } catch (error) {
          this.$toast(`Не удалось удалить пороговые баллы. ${error.message}`, {
            type: "error",
          });
          return;
        } finally {
          this.$delete(this.deleteLoading, formItem.id);
        }
      }
      form.splice(idx, 1);
    },
    async handleSubmit() {
      if (this.pending) return;
      this.error = "";
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      const { form } = this;

      const values = form.map((role) => role.value);
      if (!values.length) {
        this.handleClose();
        return;
      }
      if ([...new Set(values)].length !== values.length) {
        this.error =
          "Баллы за роли должны быть уникальны. Исправьте дублирующиеся баллы и повторите попытку";
        return;
      }
      this.pending = true;
      const requests = form.map((role) => {
        const config = {
          method: "POST",
          url: `/competences/${this.competence.id}/thresholds`,
          data: {
            lower_bound: role.value,
            role_id: role.id,
          },
        };
        if (role.setting_id) {
          config.method = "PATCH";
          config.url = pathJoin(config.url, role.setting_id);
        }
        return apiClient(config);
      });
      try {
        await Promise.all(requests);
        this.$emit("onUpdate");
      } catch (error) {
        this.$toast(`Не удалось сохранить пороговые баллы. ${error.message}`, {
          type: "error",
        });
      }
      this.pending = false;
      this.handleClose();
    },
    async handleAddRole() {
      const { form, lowerBounds, selectedRole, selectedScores } = this;
      const isValid = await this.$refs.createForm.validate();
      if (!isValid) return;
      if (form.some((n) => n.id === selectedRole)) {
        this.$refs.createForm.setErrors({
          new_role: ["Роль уже добавлена"],
        });
        return;
      }
      if (this.form.map((n) => n.value).includes(selectedScores)) {
        this.$refs.createForm.setErrors({
          new_role_scores: [
            `Баллы за роль должны отличаться от уже добавленных`,
          ],
        });
        return;
      }
      const role = this.allRoles.find((n) => n.id === selectedRole);
      if (!role) {
        this.$refs.createForm.setErrors({
          new_role: ["Роль не найдена, обновите страницу и повторите запрос"],
        });
        return;
      }
      form.push({
        value: selectedScores ?? 0,
        name: role.name,
        id: role.id,
        setting_id: lowerBounds[role.id]?.setting_id,
      });

      this.$nextTick(() => {
        this.selectedRole = null;
        this.selectedScores = 0;
        this.$refs.createForm.reset();
      });
    },
  },
};
</script>

<style></style>
