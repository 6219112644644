<template>
  <v-dialog
    :value="value"
    :max-width="maxWidth"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card>
      <v-card-title class="text-h5">Добавить трек</v-card-title>
      <v-card-text class="black--text">
        <p>Укажите какие треки должны учитываться в компетенции</p>
        <validation-observer ref="form" tag="form" @submit="handleSubmit">
          <validation-provider
            v-slot="{ errors }"
            slim
            rules="required"
            name="track_id"
          >
            <v-select
              v-model="form.track_id"
              :items="trackOptions"
              :error-messages="errors"
              label="Тип трека*"
              dense
              outlined
            ></v-select>
          </validation-provider>
          <validation-provider
            v-if="isEventTrack"
            v-slot="{ errors }"
            rules="double"
            name="penalty"
          >
            <v-text-field
              v-model.number="form.penalty"
              dense
              outlined
              :error-messages="errors"
              label="Пенальти"
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            :rules="{ required: true, double: true, max_value: 1, is_not: 0 }"
            slim
            name="weight"
          >
            <v-text-field
              v-model.number="form.weight"
              dense
              outlined
              :error-messages="errors"
              type="number"
              label="Весовой коэффициент трека"
              hint="Введите число меньше 1 но больше 0"
            ></v-text-field>
          </validation-provider>

          <div>
            <p class="mb-0"><strong>Ограничения максимального балла</strong></p>
            <p>
              Число, которым будет ограничен итоговый балл за трек (после
              применения весового коэффициента). 0 - означает, что все участники
              получат 0 баллов. Если вы не хотите ограничивать максимальный
              балл, поставьте соответствующую галочку.
            </p>
            <v-row>
              <v-col md="6" class="mb-0 pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  :rules="{
                    required: !form.no_limit,
                    double: true,
                    min_value: 0,
                  }"
                  slim
                  name="weight"
                >
                  <v-text-field
                    v-model.number="form.score_limit"
                    dense
                    outlined
                    :disabled="form.no_limit"
                    :error-messages="errors"
                    type="number"
                    persistent-hint
                    label="Максимальный балл"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col md="6" class="mb-0 pb-0">
                <v-checkbox
                  v-model="form.no_limit"
                  class="mt-1"
                  hide-details
                  label="Не ограничивать"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-divider class="mt-4 mb-4"></v-divider>
          </div>

          <validation-provider
            v-slot="{ errors }"
            :rules="{ min: 3, max: 500 }"
            name="description"
            slim
          >
            <v-textarea
              v-model="form.description"
              rows="5"
              label="Описание"
              hint="Пока нигде не выводим"
              persistent-hint
              :error-messages="errors"
              counter
              dense
              outlined
            ></v-textarea>
          </validation-provider>
          <v-checkbox
            v-model="form.main"
            label="Главный трек"
            persistent-hint
            hint="Является ли трек главным для этой компетенции"
          >
          </v-checkbox>
        </validation-observer>
        <div v-if="error" class="error--text">{{ error }}</div>
      </v-card-text>
      <v-card-actions class="px-5 pb-4">
        <v-btn color="primary" @click="handleSubmit">Сохранить</v-btn>
        <v-btn color="primary" outlined @click="handleClose">Отмена</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  DEFAULT_DIALOG_WIDTH,
  TRACK_TYPE_EVENT,
  TRACK_TYPE_NAMES,
} from "@/constants";
const ONLY_ONCE_USAGE_TRACKS = ["kaggle", "stackoverflow", "git_repo"];
import { apiClient } from "@/api";
const initialState = () => {
  return {
    pending: false,
    error: "",
    form: {
      track_id: null,
      weight: 0,
      penalty: 0,
      main: false,
      description: "",
      score_limit: "",
      no_limit: false,
    },
  };
};
export default {
  name: "AssignTrackDialog",
  props: {
    value: Boolean,
    maxWidth: {
      type: String,
      default: DEFAULT_DIALOG_WIDTH,
    },
    competence: {
      type: Object,
    },
    selectedTracks: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      ...initialState(),
    };
  },
  computed: {
    tracks() {
      return this.$store.state.tracks;
    },
    trackOptions() {
      const selected = this.selectedTracks
        .filter((n) => n)
        .map((n) => n.track_id);
      const grouped = this.tracks.reduce((acc, track) => {
        if (!acc[track.type])
          acc[track.type] = {
            name: TRACK_TYPE_NAMES[track.type] || track.type,
            type: track.type,
            list: [],
          };
        acc[track.type].list.push({
          text: track.name,
          value: track.id,
          disabled:
            selected.includes(track.id) &&
            ONLY_ONCE_USAGE_TRACKS.includes(track.code),
        });
        return acc;
      }, {});
      return Object.values(grouped).reduce((acc, group) => {
        return [...acc, { header: group.name }, ...group.list];
      }, []);
    },
    selectedTrack() {
      return (
        this.form.track_id &&
        this.tracks.find((n) => n.id === this.form.track_id)
      );
    },
    isEventTrack() {
      return this.selectedTrack?.type === TRACK_TYPE_EVENT;
    },
  },
  watch: {
    "form.no_limit": {
      handler(val) {
        if (val) {
          this.form.score_limit = null;
        }
      },
    },
    value: {
      handler(val) {
        // скидывем форму при закрытии
        if (!val) {
          this.reset();
        }
      },
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, initialState());
      this.$refs.form.reset();
    },
    handleClose() {
      this.$emit("input", false);
    },
    async handleSubmit() {
      this.error = "";
      if (this.pending) return;
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      const payload = { ...this.form };
      // Это фронтовая галка
      delete payload.no_limit;

      // Пенальти могут быть только у треков-мероприятий
      if (!this.isEventTrack) {
        delete payload.penalty;
      }
      // main отправляем только если true
      if (!payload.main) {
        delete payload.main;
      }
      try {
        this.pending = true;
        const { data } = await apiClient({
          method: "POST",
          url: `/competences/${this.competence.id}/tracks`,
          data: payload,
        });
        this.$emit("onSubmit", data);
        this.handleClose();
      } catch (error) {
        if (error.field) {
          this.$refs.form.setErrors({
            [error.field]: [error.message],
          });
        } else {
          this.error = error.message;
        }
      }
      this.pending = false;
    },
  },
};
</script>

<style></style>
